import React from "react";
import { Navigate, Outlet } from "react-router";
import useUser from "../hooks/useUser";

export default function PrivateRoute() {
  const { token } = useUser();

  if (token) {
    return <Outlet />;
  } else {
    return <Navigate to={"/login"} />;
  }
}
