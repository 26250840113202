import React from "react";
import InputNumber from "../InputNumber";
import DatePicker from "../DatePicker";
import Input from "../Input";

export default function Informacion(props) {
  const { values, touched, errors, setFieldValue, setFieldTouched } = props;
  return (
    <div className="container mt-5 mb-5">
      <h3 className="text-lg font-medium mt-5">
        FECHA DEL PROYECTO Y NÚMERO DE PERSONAS QUE PARTICIPAN
      </h3>
      <p className="text-sm font-medium mt-5">
        Selecciona la fecha inicial y final del Proyecto y el número de personas
        que participarán en él
      </p>
      <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
        <DatePicker
          minDate={new Date()}
          value={values.fecha_inicial}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.fecha_inicial}
          touched={touched.fecha_inicial}
          nombre={"fecha_inicial"}
          title={"Fecha Inicial del Proyecto"}
        />
        <DatePicker
          minDate={values.fecha_inicial}
          value={values.fecha_final}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.fecha_final}
          touched={touched.fecha_final}
          nombre={"fecha_final"}
          title={"Fecha Final del Proyecto"}
        />
        <InputNumber
          value={values.numero_usuarios}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.numero_usuarios}
          touched={touched.numero_usuarios}
          nombre={"numero_usuarios"}
          title={"Número de Usuarios Requeridos"}
        />
        <InputNumber
          value={values.total_personas}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.total_personas}
          touched={touched.total_personas}
          nombre={"total_personas"}
          title={"Total de personas involucradas en la Producción"}
        />
      </div>
      <h3 className="text-lg font-medium mt-5">INFORMACIÓN DE LA CUENTA</h3>
      <h5 className="text-sm font-medium mt-5">
        Ingresa los datos para completar la cuenta.
      </h5>
      <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
        <Input
          value={values.correo_electronico}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.correo_electronico}
          touched={touched.correo_electronico}
          nombre={"correo_electronico"}
          title={"Correo Electrónico"}
        />
        <Input
          value={values.confir_correo_electronico}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.confir_correo_electronico}
          touched={touched.confir_correo_electronico}
          nombre={"confir_correo_electronico"}
          title={"Confirmación Correo Electrónico"}
        />
        <Input
          value={values.nombre_completo}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.nombre_completo}
          touched={touched.nombre_completo}
          nombre={"nombre_completo"}
          title={"Nombre del Administrador de la Cuenta (Nombre y Apellidos)"}
        />
        <Input
          value={values.contraseña}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.contraseña}
          touched={touched.contraseña}
          password={true}
          nombre={"contraseña"}
          title={"Contraseña"}
        />
      </div>
    </div>
  );
}
