import React from "react";
import Input from "../Input";

export default function Proyecto(props) {
  const { values, touched, errors, setFieldValue, setFieldTouched } = props;
  return (
    <div className="container mt-5 mb-5">
      <h3 className="text-lg font-medium mt-5">NOMBRE DEL PROYECTO</h3>
      <div className="mt-5 grid grid-cols-1 gap-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
        <Input
          value={values.nombre_proyecto}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.nombre_proyecto}
          touched={touched.nombre_proyecto}
          nombre={"nombre_proyecto"}
          title={"Nombre del Proyecto"}
        />
                <Input
          value={values.nombre_empresa}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.nombre_empresa}
          touched={touched.nombre_empresa}
          nombre={"nombre_empresa"}
          title={"Nombre de la Empresa"}
        />
      </div>
    </div>
  );
}
