import * as Yup from "yup";

export const ProductionData = Yup.object().shape({
  nombre_proyecto: Yup.string().required(
    "El nombre del proyecto es obligatorio"
  ),
  nombre_empresa: Yup.string().required(
    "El nombre de la empresa es obligatorio"
  ),
  total_personas: Yup.number()
    .test((val, { createError, path }) => {
      if (val === 0) {
        return createError({
          path,
          message: "El total de personas debe ser mayor a 0",
        });
      } else return true;
    })
    .typeError("El total de personas debe ser un número")
    .required("El total de personas es obligatorio"),
  numero_usuarios: Yup.number()
    .test((val, { createError, path }) => {
      if (val === 0) {
        return createError({
          path,
          message: "El número de usuarios debe ser mayor a 0",
        });
      } else return true;
    })
    .typeError("El número de usuarios debe ser un número")
    .required("El nombre de la empresa es obligatorio"),
  correo_electronico: Yup.string()
    .email("El correo electrónico es inválido")
    .required("El correo electronico es obligatorio"),
  nombre_completo: Yup.string().required("El nombre completo es obligatorio"),
  contraseña: Yup.string().required("La contraseña es obligatoria"),
  tipo_persona: Yup.object().required("El tipo de persona es obligatorio"),
  registro_federal: Yup.string().required("El registro federal es obligatorio"),
  razon_social: Yup.string().required("La razón social es obligatoria"),
  telefono_contacto: Yup.string().required(
    "El teléfono de contacto es obligatorio"
  ),
  direccion: Yup.string().required("La dirección es obligatoria"),
  colonia: Yup.object().required("La colonia es obligatoria"),
  municipio: Yup.object().required("El municipio es obligatorio"),
  estado: Yup.object().required("El estado es obligatorio"),
  pais: Yup.object().required("El país es obligatorio"),
  cp: Yup.object().required("El código postal es obligatorio"),

  fecha_inicial: Yup.string().required("La fecha inicial es obligatoria"),
  fecha_final: Yup.string().required("La fecha final es obligatoria"),

  confir_correo_electronico: Yup.string()
    .email("El correo electrónico es inválido")
    .required("El correo electrónico es obligatorio"),
});
