import React, { useEffect } from "react";

export default function Total(props) {
  const { values, setFieldValue } = props;

  useEffect(() => {
    if (
      values.fecha_inicial !== "" &&
      values.fecha_final !== "" &&
      values.numero_usuarios !== "" &&
      values.total_personas !== ""
    ) {
      const numeroMeses = monthDiff(values.fecha_inicial, values.fecha_final);

      const T1 = numeroMeses * 500 * parseFloat(values.numero_usuarios);
      const T2 = numeroMeses * 20 * parseFloat(values.total_personas);
      const subtotal = T1 + T2;
      const iva = subtotal * 0.16;
      const Total = subtotal + iva;

      setFieldValue("total", Total.toFixed(2));
      setFieldValue("iva", iva.toFixed(2));
      setFieldValue("subtotal", subtotal.toFixed(2));
    }
    return () => {};
  }, [
    setFieldValue,
    values.fecha_final,
    values.fecha_inicial,
    values.numero_usuarios,
    values.total_personas,
  ]);

  function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth() + 1;
    months += d2.getMonth();
    if (d2.getDate() >= d1.getDate() || d2.getDate() <= d1.getDate()) months++;

    return months <= 0 ? 0 : months;
  }

  return (
    <div className="container mt-5 mb-5 text-right">
      <h3 className="text-lg font-medium mt-5">
        Fecha inicial: {new Date(values.fecha_inicial).toLocaleDateString()}
      </h3>
      <h3 className="text-lg font-medium mt-5">
        Fecha final: {new Date(values.fecha_final).toLocaleDateString()}
      </h3>
      <h3 className="text-lg font-medium mt-5">
        Numero de usuario: {values.numero_usuarios}
      </h3>
      <h3 className="text-lg font-medium mt-5">
        Total de personas: {values.total_personas}
      </h3>
      <h3 className="text-lg font-medium mt-5">Subtotal: ${values.subtotal}</h3>
      <h3 className="text-lg font-medium mt-5">IVA: ${values.iva}</h3>
      <h3 className="text-lg font-medium mt-5">Total: ${values.total}</h3>
    </div>
  );
}
