import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import Swal from "sweetalert2";
import { Guardar } from "../../services/productiondata";

const paypalScriptOptions = {
  "client-id": 'AeWvzGdNLFcAKcdnLaM-JvPBkkknHk-xZLjZP10BO02cxW-xAm_IAT4MgJpSAdoXfuQBxR6limgWrF8L',
  currency: "MXN",
};

export default function Modal(props) {
  const { open, values, resetForm } = props;

  return (
    open && (
      <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
        <PayPalScriptProvider options={paypalScriptOptions}>
          <Button values={values} resetForm={resetForm}/>
        </PayPalScriptProvider>
      </div>
    )
  );
}

function Button({ values, resetForm }) {
  /**
   * usePayPalScriptReducer use within PayPalScriptProvider
   * isPending: not finished loading(default state)
   * isResolved: successfully loaded
   * isRejected: failed to load
   */
  const [{ isPending }] = usePayPalScriptReducer();
  const paypalbuttonTransactionProps = {
    style: { layout: "vertical" },
    createOrder(data, actions) {
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: values.total,
            },
          },
        ],
      });
    },
    
    onCancel: () => {
      Swal.fire({
        title: "Cancelado",
        text: "Pago cancelado",
        icon: "error",
        confirmButtonText: "OK",
      });
    },
    onError: function(err) {
      console.log(err);
    },
    onApprove(data, actions) {
      return actions.order.capture({}).then((details) => {
        Swal.fire({
          title: "Enviando...",
          timer: 5000,
          timerProgressBar: true,
          didOpen: async () => {
            Swal.showLoading();
            const res = await Guardar(values);
            if (res.success) {
              Swal.fire({
                title: "¡Listo!",
                text: "Su proyecto ha sido enviado",
                icon: "success",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.scrollTo(0, 0);
                  resetForm();
                }
              });
            } else {
              Swal.fire({
                title: "Error",
                text: "Ha ocurrido un error",
                icon: "error",
                confirmButtonText: "OK",
              });
            }
          },
        })
      });
    },
  };
  return (
    <>
      {isPending ? <h2>Cargando botones...</h2> : null}
      <PayPalButtons {...paypalbuttonTransactionProps} />
    </>
  );
}
