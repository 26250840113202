import Card from "../Card";

export default function Modal(props) {
  const { open } = props;

  return (
    open && (
      <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-lg w-full sm:p-6">
        <Card title="Pago tarjeta" {...props} />
      </div>
    )
  );
}
