import { Formik } from "formik";
import Proyecto from "../../components/Home/Proyecto";
import Informacion from "../../components/Home/Informacion";
import Facturacion from "../../components/Home/Facturacion";
import Pago from "../../components/Home/Pago";
import Dashboard from "../../components/Home/Dashboard";
import Total from "../../components/Home/Total";
import { Guardar } from "../../services/productiondata";
import Swal from "sweetalert2";
import { ProductionData } from "../../utils/ValidationProductionData";

export default function Home() {
  return (
    <Dashboard>
      <Formik
        initialValues={{
          nombre_proyecto: "",
          nombre_empresa: "",
          fecha_inicial: new Date(),
          fecha_final: new Date(),
          numero_usuarios: 0,
          total_personas: 0,
          correo_electronico: "",
          confir_correo_electronico: "",
          nombre_completo: "",
          contraseña: "",
          tipo_persona: "",
          registro_federal: "",
          razon_social: "",
          telefono_contacto: "",
          direccion: "",
          colonia: "",
          colonias: [],
          municipio: "",
          municipios: [],
          estado: "",
          estados: [],
          pais: "",
          paises: [],
          cp: "",
          cps: [],
          tipo_pago: "",
          nombre_tarjeta: "",
          numero_tarjeta: "",
          cvv: "",
          iva: 0,
          subtotal: 0,
          total: 0,
          pagado: false,
          paymentID: "",
          billingToken: "",
          payerID: "",
          orderID: "",
          facilitatorAccessToken: "",
        }}
        onSubmit={(values, { resetForm }) => {
          if (values.pagado) {
            Swal.fire({
              title: "Enviando...",
              timer: 5000,
              timerProgressBar: true,
              didOpen: async () => {
                Swal.showLoading();
                const res = await Guardar(values);
                if (res.success) {
                  resetForm();
                  Swal.fire({
                    title: "¡Listo!",
                    text: "Su proyecto ha sido enviado",
                    icon: "success",
                    confirmButtonText: "OK",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      window.scrollTo(0, 0);
                    }
                  });
                } else {
                  Swal.fire({
                    title: "Error",
                    text: "Ha ocurrido un error",
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                }
              },
            });
          } else {
            Swal.fire({
              title: "Error",
              text: "Debe de pagar para enviar su proyecto",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        }}
        validationSchema={ProductionData}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <div>
              <h2 className="sr-only">Contacto</h2>
              <div className="grid grid-cols-1 lg:grid-cols-1">
                <div className="py-3 sm:px-5 divide-y lg:col-span-2 xl:px-5">
                  <Proyecto {...props} />
                  <Informacion {...props} />
                  <Facturacion {...props} />
                  {props.values.pagado === true ? (
                    <div className="container mt-5 mb-5">
                      <h3 className="text-lg font-medium mt-5">
                        Pago realizado
                      </h3>
                      <p className="text-sm font-medium mt-5">
                        Gracias por su compra, da click en guardar para
                        finalizar.
                      </p>
                    </div>
                  ) : (
                    <Pago {...props} />
                  )}
                  <Total {...props} />
                  {/*<div className="text-center pt-10">
                    <button
                      type="submit"
                      className="px-8 py-3 text-white bg-blue-600 rounded focus:outline-none disabled:opacity-75"
                    >
                      Guardar Informacion
                    </button>
                  </div>*/}
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Dashboard>
  );
}
