const ENDPOINT = "https://lcicorporativo.co/sistema-formulario/public/api/"

export async function Login(data) {
    return fetch(`${ENDPOINT}login`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        },
        method: 'post',
        body: JSON.stringify(data)
    }).then(res => res.json()).then(response => {
        return response;
    });
}
