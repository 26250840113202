import { useEffect } from "react";
import { Token, Cliente, Pago } from "../services/pago";
import Swal from "sweetalert2";
import { Guardar } from "../services/productiondata";
import { useNavigate } from "react-router-dom";

export default function Card(props) {
  const { values } = props;
  const navigation = useNavigate();

  useEffect(() => {
    Token().then((res) => {
      const { success } = res;
      if (success) {
        const { data } = res;
        createTarjeta(data.checkout.id);
      } else {
        Swal.fire({
          title: "Error",
          text: "No se pudo obtener el token",
          icon: "error",
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createTarjeta = (requestId) => {
    window.ConektaCheckoutComponents.Card({
      targetIFrame: "#conektaIframeContainer",
      allowTokenization: true,
      checkoutRequestId: requestId,
      /*
      publicKey: "key_DaEbP7stWDRrxPsbdb2BgQQ",
      */
      publicKey: "key_Ah8r29gUn9WxgjLoRDasPbA",
      options: {
        styles: {
          inputType: "rounded",
          buttonType: "rounded",
          states: {
            empty: {
              borderColor: "#FFAA00",
            },
            invalid: {
              borderColor: "#FF00E0",
            },
            valid: {
              borderColor: "#0079c1",
            },
          },
        },
        languaje: "es",
        button: {
          colorText: "#ffffff",
          backgroundColor: "#301007",
        },
        iframe: {
          colorText: "#65A39B",
          backgroundColor: "#FFFFFF",
        },
      },
      onCreateTokenSucceeded: function (token) {
        Cliente({
          name: values.nombre_completo,
          email: values.correo_electronico,
          phone: "+52" + values.telefono_contacto,
          token_id: token.id,
        })
          .then((res) => {
            const { success } = res;
            if (success) {
              realizarPago(res.data);
            } else {
              Swal.fire({
                title: "Error",
                text: "Ha ocurrido un error",
                icon: "error",
              });
            }
          })
          .catch((err) => {
            Swal.fire({
              title: "Error",
              text: "Ha ocurrido un error",
              icon: "error",
            });
          });
      },
      onCreateTokenError: function (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
        });
      },
    });
  };

  const realizarPago = (pago) => {
    Pago({
      amount: values.subtotal,
      iva: values.iva,
      token: pago.id,
    }).then((res) => {
      const { success, message } = res;
      if (success) {
        Swal.fire({
          title: "Enviando...",
          timer: 5000,
          timerProgressBar: true,
          didOpen: async () => {
            Swal.showLoading();
            const res = await Guardar(values);
            if (res.success) {
              Swal.fire({
                title: "¡Listo!",
                text: "Su proyecto ha sido enviado",
                icon: "success",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.scrollTo(0, 0);
                  navigation(
                    "/sistema-formulario-front/congratulations/" +
                      res.data.user_id
                  );
                }
              });
            } else {
              Swal.fire({
                title: "Error",
                text: "Ha ocurrido un error",
                icon: "error",
                confirmButtonText: "OK",
              });
            }
          },
        });
      } else {
        Swal.fire({
          title: "Error",
          text: message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    });
  };

  return (
    <div
      id="conektaIframeContainer"
      className="w-full"
      style={{ height: 600 }}
    ></div>
  );
}
