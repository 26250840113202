import React from "react";
import Select from "react-select";

export default function Myselect({
  value,
  options,
  onChange,
  onBlur,
  error,
  touched,
  nombre,
  title,
}) {
  const handleChange = async (e) => {
    onChange(nombre, e);
  };

  const handleBlur = () => {
    onBlur(nombre, true);
  };

  return (
    <>
      <div className="col-md-4">
        <label>
          {title}
          <span className="text-red-500">*</span>:
        </label>
        <Select
          id={nombre}
          options={options}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          className="w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-400 rounded-full"
          isLoading={options.length !== 0 ? false : true}
          loadingMessage={() => "Buscando..."}
          placeholder="Seleccione una opción"
        />
        {!!error && touched && (
          <div style={{ color: "red", marginTop: ".5rem" }}>{error}</div>
        )}
      </div>
    </>
  );
}
