/* eslint-disable no-sparse-arrays */
export const estado_prestador = [
    { value: 'Activo', label: 'Activo' },
    { value: 'Inactivo', label: 'Inactivo' },
]
export const tipo_pago = [
    { value: 'Tarjeta_de_Credito', label: 'Tarjeta de Crédito' },
    { value: 'Paypal', label: 'Paypal' },
    /*{ value: 'Transferencia', label: 'Transferencia' },
    { value: 'Oxxo', label: 'Oxxo' },*/
]
export const puesto = [
    { value: 'Nomina', label: 'Nomina' },,
    { value: 'Honorarios', label: 'Honorarios' },
    { value: 'Extranjero', label: 'Extranjero' },
    { value: 'Asalariados', label: 'Asalariados' },
]
export const denominacion = [
    { value: '(pesos 00/100 M.N)', label: '(pesos 00/100 M.N)' },
    { value: '(dolares 00/100 USD)', label: '(dolares 00/100 USD)' },
]
export const tipo_persona = [
    { value: 'Fisica', label: 'Fisica' },
    { value: 'Moral', label: 'Moral' },
]
export const si_no = [
    { value: 'Si', label: 'Si' },
    { value: 'No', label: 'No' },
]

export const metodo_pago = [
    { value: 'PUE-Pago en una sola exhibición', label: 'PUE-Pago en una sola exhibición' },
    { value: 'PDD-Pago en parcialidades o diferido', label: 'PDD-Pago en parcialidades o diferido' },
]
export const tipo_comprobante = [
    { value: 'I - Ingreso', label: 'I - Ingreso' },
    { value: 'E - Egreso', label: 'E - Egreso' },
    { value: 'T - Translado', label: 'T - Translado' },
    { value: 'N - Nómina', label: 'N - Nómina' },
    { value: 'P - Pago', label: 'P - Pago' },
]
export const forma_pago = [
    { value: 'Quincenal', label: 'Quincenal' },
    { value: 'Mensual', label: 'Mensual' },
    { value: 'Semanal', label: 'Semanal' },
    { value: 'Único', label: 'Único' },
    { value: 'X Hora', label: 'X Hora' },
    { value: 'X Dia', label: 'X Dia' },
]
export const cfdi = [
    { value: 'Si', label: 'Si' },
    { value: 'No', label: 'No' },
]

export const paises = [
    { value: 'Mexico', label: 'Mexico' },
]