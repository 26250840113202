const APP_URL = "https://lcicorporativo.co/sistema-formulario/public/api"

export async function EstadosGet() {
    return fetch(`${APP_URL}/estados`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        },
        method: 'get'
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}

export async function MunicipiosGet({
    id,
}) {
    return fetch(`${APP_URL}/municipios`, {

        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        },
        method: 'post',
        body: JSON.stringify({
            id
        })
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}

export async function CPGet({
    estado_id,
    municipio_id
}) {
    return fetch(`${APP_URL}/cp`, {

        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        },
        method: 'post',
        body: JSON.stringify({
            estado_id,
            municipio_id
        })
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}

export async function ColoniaGet({
    estado_id,
    municipio_id,
    cp_id
}) {
    return fetch(`${APP_URL}/colonia`, {

        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        },
        method: 'post',
        body: JSON.stringify({
            estado_id,
            municipio_id,
            cp_id
        })
    })
        .then(res => res.json()).then(response => {
            return response;
        });
}