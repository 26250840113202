import { useState, useEffect } from "react";
import MySelect from "../MySelect";
import { tipo_pago } from "../../utils/SelectInfo";
import Modal from "./Modal";
//import ModalOxxo from "./ModalOxxo";
import ModalPaypal from "./ModalPaypal";
//import ModalTransferencia from "./ModalTransferencia";

export default function Pago(props) {
  const { values, touched, errors, setFieldValue, setFieldTouched } = props;
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  /*const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);*/

  useEffect(() => {
    if (values.tipo_pago !== "") {
      if (values.tipo_pago.value === "Tarjeta_de_Credito") {
        setOpen(true);
        setOpen1(false);
        /*setOpen2(false);
        setOpen3(false);*/
      } else if (values.tipo_pago.value === "Paypal") {
        setOpen1(true);
        setOpen(false);
        /*setOpen2(false);
        setOpen3(false);*/
      } else if (values.tipo_pago.value === "Transferencia") {
        //setOpen2(true);
        setOpen(false);
        setOpen1(false);
        //setOpen3(false);
      } else if (values.tipo_pago.value === "Oxxo") {
        //setOpen3(true);
        setOpen(false);
        setOpen1(false);
        //setOpen2(false);
      }
    }
    return () => {};
  }, [values.tipo_pago]);

  return (
    <div className="container mt-5 mb-5">
      {values.total > 0 && (
        <>
          <h3 className="text-lg font-medium mt-5">PAGO</h3>
          <p className="text-sm font-medium mt-5">
            Ingrese los datos para realizar su pago
          </p>
          <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
            <MySelect
              value={values.tipo_pago}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              error={errors.tipo_pago}
              touched={touched.tipo_pago}
              options={tipo_pago}
              nombre={"tipo_pago"}
              title={"Tipo de Pago"}
            />
          </div>
          <div className="mt-2 justify-content-center">
            <Modal open={open} setOpen={setOpen} {...props} />
            <ModalPaypal open={open1} setOpen={setOpen1} {...props} />
            {/*<ModalTransferencia open={open2} setOpen={setOpen2} {...props} />
            <ModalOxxo open={open3} setOpen={setOpen3} {...props} />*/}
          </div>
        </>
      )}
    </div>
  );
}
