export default function Input({
  value,
  onChange,
  onBlur,
  error,
  touched,
  nombre,
  title,
  password,
}) {
  const handleChange = (e) => {
    onChange(nombre, e.target.value);
  };

  const handleBlur = () => {
    onBlur(nombre, true);
  };
  return (
    <div className="col-md-4">
      <label>{title}<span className="text-red-500">*</span>:</label>
      <input
        type={password ? "password" : "text"}
        id={nombre}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        className="w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-400 rounded-full"
      />
      {!!error && touched && (
        <div style={{ color: "red", marginTop: ".5rem" }}>{error}</div>
      )}
    </div>
  );
}
