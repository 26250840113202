const ENDPOINT = "https://lcicorporativo.co/sistema-formulario/public/api/";

export async function Pago(data) {
  return fetch(`${ENDPOINT}pago`, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    method: "post",
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

export async function Token() {
  return fetch(`${ENDPOINT}token`, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    method: "post",
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}

export async function Cliente(data) {
  return fetch(`${ENDPOINT}cliente`, {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    method: "post",
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
}
