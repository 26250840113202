import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Loader from "./components/Loader";
import { UserContextProvider } from "./context/userContext";

import PrivateRoute from "./route/PrivateRoute";

const NotFound = lazy(() => import("./components/404"));
const Login = lazy(() => import("./pages/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Contacto = lazy(() => import("./pages/Contacto"));
const Contactos = lazy(() => import("./pages/Backend/Contactos"));
const Contratos = lazy(() => import("./pages/Backend/Contratos"));
const Documentos = lazy(() => import("./pages/Backend/Documentos"));
const LLamados = lazy(() => import("./pages/Backend/LLamados"));
const Profile = lazy(() => import("./pages/Backend/Profile"));
const Presupuestos = lazy(() => import("./pages/Backend/Presupuestos"));

function App() {
  return (
    <UserContextProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/contacto/:proyecto"
          element={
            <Suspense fallback={<Loader />}>
              <Contacto />
            </Suspense>
          }
        />
        <Route
          path="/login"
          element={
            <Suspense fallback={<Loader />}>
              <Login />
            </Suspense>
          }
        />

        <Route path="home" element={<PrivateRoute />}>
          <Route
            index
            element={
              <Suspense fallback={<Loader />}>
                <Dashboard />
              </Suspense>
            }
          />

          <Route
            path="contactos"
            element={
              <Suspense fallback={<Loader />}>
                <Contactos />
              </Suspense>
            }
          />
          <Route
            path="contratos"
            element={
              <Suspense fallback={<Loader />}>
                <Contratos />
              </Suspense>
            }
          />
          <Route
            path="documentos"
            element={
              <Suspense fallback={<Loader />}>
                <Documentos />
              </Suspense>
            }
          />
          <Route
            path="llamados"
            element={
              <Suspense fallback={<Loader />}>
                <LLamados />
              </Suspense>
            }
          />
          <Route
            path="presupuestos"
            element={
              <Suspense fallback={<Loader />}>
                <Presupuestos />
              </Suspense>
            }
          />
          <Route
            path="perfil"
            element={
              <Suspense fallback={<Loader />}>
                <Profile />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="*"
          element={
            <Suspense fallback={<Loader />}>
              <NotFound />
            </Suspense>
          }
        />
      </Routes>
    </UserContextProvider>
  );
}

export default App;
