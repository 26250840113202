import { useEffect } from "react";
import InputNumber from "../InputNumber";
import Input from "../Input";
import MySelect from "../MySelect";
import { tipo_persona, paises } from "../../utils/SelectInfo";
import {
  EstadosGet,
  MunicipiosGet,
  CPGet,
  ColoniaGet,
} from "../../services/estados";

export default function Facturacion(props) {
  const { values, touched, errors, setFieldValue, setFieldTouched } = props;

  useEffect(() => {
    EstadosGet().then((res) => {
      setFieldValue("estados", res);
    });
    return () => {};
  }, [setFieldValue]);

  useEffect(() => {
    if (values.estado !== "") {
      MunicipiosGet({ id: values.estado.value }).then((res) => {
        setFieldValue("municipios", res);
      });
    }
    return () => {};
  }, [setFieldValue, values.estado]);

  useEffect(() => {
    if (values.municipio !== "") {
      CPGet({
        estado_id: values.estado.value,
        municipio_id: values.municipio.value,
      }).then((res) => {
        setFieldValue("cps", res);
      });
    }
    return () => {};
  }, [setFieldValue, values.estado.value, values.municipio]);

  useEffect(() => {
    if (values.cp !== "") {
      ColoniaGet({
        estado_id: values.estado.value,
        municipio_id: values.municipio.value,
        cp_id: values.cp.value,
      }).then((res) => {
        setFieldValue("colonias", res);
      });
    }
    return () => {};
  }, [setFieldValue, values.cp, values.estado.value, values.municipio.value]);

  return (
    <div className="container mt-5 mb-5">
      <h3 className="text-lg font-medium mt-5">INFORMACIÓN DE FACTURACIÓN</h3>
      <p className="text-sm font-medium mt-5">
        Ingresa los datos de facturación
      </p>
      <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
        <MySelect
          value={values.tipo_persona}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.tipo_persona}
          touched={touched.tipo_persona}
          options={tipo_persona}
          nombre={"tipo_persona"}
          title={"Tipo de Persona"}
        />
        <Input
          value={values.registro_federal}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.registro_federal}
          touched={touched.registro_federal}
          nombre={"registro_federal"}
          title={"Registro Federal de Contribuyentes"}
        />
        <Input
          value={values.razon_social}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.razon_social}
          touched={touched.razon_social}
          nombre={"razon_social"}
          title={"Razón Social"}
        />
        <InputNumber
          value={values.telefono_contacto}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.telefono_contacto}
          touched={touched.telefono_contacto}
          nombre={"telefono_contacto"}
          title={"Teléfono de Contacto"}
        />
        <MySelect
          value={values.estado}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.estado}
          touched={touched.estado}
          options={values.estados}
          nombre={"estado"}
          title={"Estado"}
        />
        <MySelect
          value={values.municipio}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.municipio}
          touched={touched.municipio}
          options={values.municipios}
          nombre={"municipio"}
          title={"Municipio"}
        />
        <MySelect
          value={values.cp}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.cp}
          touched={touched.cp}
          options={values.cps}
          nombre={"cp"}
          title={"Código Postal"}
        />
        <Input
          value={values.direccion}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.direccion}
          touched={touched.direccion}
          nombre={"direccion"}
          title={"Dirección (Calle y Número)"}
        />
        <MySelect
          value={values.colonia}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.colonia}
          touched={touched.colonia}
          options={values.colonias}
          nombre={"colonia"}
          title={"Colonia"}
        />
        <MySelect
          value={values.pais}
          onChange={setFieldValue}
          onBlur={setFieldTouched}
          error={errors.pais}
          touched={touched.pais}
          options={paises}
          nombre={"pais"}
          title={"País"}
        />
      </div>
    </div>
  );
}
